@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  border-radius: .25rem;
  width: 100%;
  line-height: 1;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.react-loading-skeleton:after {
  content: " ";
  display: var(--pseudo-element-display);
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, var(--base-color), var(--highlight-color), var(--base-color));
  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  height: 100%;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateX(-100%);
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none;
  }
}
/*# sourceMappingURL=index.bdbec509.css.map */
